// @flow

import React, { Component } from 'react';
import { withRouter, Link, Switch, Route, Redirect } from 'react-router-dom'

import { Layout, Menu, Icon, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import HomePage from '../../../containers/HomePage';

import AppHeader from '../../../components/AppHeader';
import { AppContent } from '../../../components/AppContent';
import SidePanel from '../../../components/SidePanel';
import SideDrawer from '../../../components/SideDrawer';
import { isMobile } from '../../../utils/common-utils';
import { toggleSidePanel } from '../../../actions/commonActions';
import { logout } from '../../login/actions/loginActions';

import UsersDashboad from '../../users/UsersDashboad';
import OrganisationsHome from '../../organisations/containers/OrganisationsHome';
import { DocumentDashboard } from '../../documents/DocumentDashboad';
import { setBreadcrumb } from '../../../actions/commonActions';
import { Event } from "../../../components/Analytics";
import CourseTaskView from '../../courses/containers/CourseTaskView';

const { Content } = Layout;
const SubMenu = Menu.SubMenu;
const maxCharacterLength = 19;

export class MainDashboardPage extends Component {

  constructor(props) {
    super(props);
    this.state = { sideMenuItems: [] };
  }
  componentDidMount = async () => {
    if (this.props.orgList) {
      this.setSideMenuItems(this.props.orgList);
    }
  }

  componentDidUpdate = async (prevProps) => {
    if (this.props.orgList && prevProps.orgList !== this.props.orgList) {
      this.setSideMenuItems(this.props.orgList);
    }
    if(this.props.location.pathname==='/dashboard'){
      this.props.setBreadcrumb("");
    }
  }

  onMenuclicked = () => {
    if (isMobile()) {
      this.props.toggleSidePanel(false);
    }
  };

  moduleEvent = (category) => {
    Event(category, "click side menu", category+" module");
  };

  handleMenuClick = (moduleName) => {
    this.onMenuclicked();
    this.moduleEvent(moduleName);
  }

  buildMenuItems = (items) => {
    return items.map((i) => {
      if (i.children) {
        return (
          <SubMenu key={(i.key) ? i.key : i.name.toLowerCase()
          } title={< span > <Icon type={i.icon} /> <span>{i.name}</span></span >}>
            {this.buildMenuItems(i.children)}
          </SubMenu >
        )
      }
      else {
        return (
          <Menu.Item key={(i.key) ? i.key : i.name.toLowerCase()}>
            {i.name.length > maxCharacterLength ? (
              <Tooltip className='tooltip-text' placement='top' title={i.name}>
              <Link to={i.url} onClick={() => this.handleMenuClick(i.name)}>
                <Icon type={i.icon} />
                  <span className="truncated-text">{i.name}</span>
              </Link>
              </Tooltip>
            ): (
              <Link to={i.url} onClick={() => this.handleMenuClick(i.name)}>
                <Icon type={i.icon} />
                <span>{i.name}</span>
              </Link>
            )}
          </Menu.Item>
        );
      }
    });
  }

  getOrgChildren = async (orgList) => {
    return orgList.map(org => { return { name: org['org_name'], icon: 'bank', url: `/org/${org['org_id']}`, key: `${org['org_id']}` } });
  }

  /**
   * Construct the side menu items
   */
  setSideMenuItems = async (orgList) => {
    const orgChildren = await this.getOrgChildren(orgList);
    let menuItemsList = [
      ...(orgChildren.length > 0 ? [{ name: 'Dashboard', icon: 'dashboard', url: '/dashboard', key: 'dashboard' }]:[]),
      { name: 'Documents', icon: 'file-text', url: '/documents', key: 'documents' },
      ...(this.props.isAdmin?[{ name: 'Users', icon: 'team', url: '/users', key: 'users' }]:[]),
      ...(orgChildren.length > 0 ? [{
        name: 'Organisations', icon: 'deployment-unit', url: '/organisations', key: 'organisations',
        children: orgChildren
      }]:[])
    ];
    const sideMenuItems = this.buildMenuItems(menuItemsList);
    await this.setState({ sideMenuItems });
  }

  getView = (route) => {
    let view;
    switch (route.match.params.page) {
      case 'documents':
        view = (<DocumentDashboard />)
        break;
      case 'users':
        view = (<UsersDashboad />)
        break;
      case 'org':
        view = (<OrganisationsHome orgId={route.match.params.param} />)
        break;
      case 'course':
        view = (<CourseTaskView reqId={route.match.params.param} />)
        break;
      case 'site_course':
        view = (<CourseTaskView reqId={route.match.params.param}  isSiteReq={true}/>)
        break;
      default:
        view = (<HomePage />)
        break;
    }
    return view;
  }

  renderSidePanel = () => {
    if (isMobile()) {
      return (
        <SideDrawer>
          {this.state.sideMenuItems}
          <Menu.Item key="logout" onClick={this.props.logout}>
            <Icon type="logout" />
            {'Logout'}
          </Menu.Item>
        </SideDrawer>
      )
    }
    else {
      return (
        <SidePanel>
          {this.state.sideMenuItems}
        </SidePanel>
      )
    }
  }

  render() {
    return (
      <Layout style={{ minHeight: '100vh' }}>
        {this.renderSidePanel()}
        <Content>
          <Layout>
            <AppHeader logout={this.props.logout} />
            <AppContent>
              <Switch>
                <Route exact path='/' render={this.getView} />
                <Route path={'/:page/:param'} render={this.getView} />
                <Route path={'/:page'} render={this.getView} />
                <Redirect to='/' />
              </Switch>
            </AppContent>
          </Layout>
        </Content>
      </Layout>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    orgList: state.getIn(['loginUserState', 'orgList']),
    isAdmin: state.getIn(['loginUserState', 'isAdmin']),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    logout: bindActionCreators(logout, dispatch),
    toggleSidePanel: bindActionCreators(toggleSidePanel, dispatch),
    setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainDashboardPage));



